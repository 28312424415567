import './scripts/common';

import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:

import * as ajaxModal from './scripts/ajax-modal';
ajaxModal.init();

import * as dynamicTabbing from './scripts/dynamic-tabbing';
dynamicTabbing.init();

import * as slider from './scripts/slider';
slider.init();

import * as responsiveTable from './scripts/responsive-table';
responsiveTable.init();

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-bs-toggle="tab"]',function (element) {
    new Tab(element);
});

import Scrollspy from 'bootstrap/js/dist/scrollspy';
onFind('[data-bs-spy="scroll"]',function (element) {
    new Scrollspy(element);
});

import * as affix from "@elements/affix";
affix.init({
    offset: 115,
    breakpoints: {
        768: {
            offset: 100
        },
        1200: {
            offset: 160
        }
    }
});


